@import "~antd/dist/antd.less";

/* .login-box {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 0, 0, 0.3);
}
.ant-menu-overflow.ant-menu-horizontal{
  justify-content: flex-end;
}
.login_form_wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .ant-form-item-control-input-content{
    height: 3em;
  }
  .ant-input-affix-wrapper{
    height: 100%;
  }
}
.raymond_login_logo_wrapper{
  // background: red;
  margin-bottom: 2.5em;
  padding: 11px 10px;
}



@media (max-width: 415px) {
  .ant-table-content{
    overflow-x: scroll;
  }
  .ant-card-body{
    overflow-x: scroll;
  
  }
}
.menu {
  margin-left: 10px;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
@primary-color: #022249;@error-color: #ee2c3c;